import React, { Component } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { navigate, Link } from 'gatsby';
import Loader from './admin/modal-overlays/loader';
import AgsIcon from '../images/ags-icon.png';
import TechIcon from '../images/tech-icon.png';
import ETFIcon from '../images/etf-icon.png';
import FinIcon from '../images/fin-icon.png';
import SEO from '../components/SEO';
import { Helmet } from 'react-helmet';

class UserProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_products: [],
      additional_products: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.checkUser();
    }, 500);
  }

  checkUser = () => {
    this.setState({ isLoading: true });
    let _self = this;
    firebase.auth().onAuthStateChanged((authenticated) => {
      if (authenticated) {
        _self.setState({ isLoading: false });
        this.getAllProducts();
      } else {
        navigate('/login');
        _self.setState({ isLoading: false });
      }
    });
  };

  getAllProducts = () => {
    firebase
      .firestore()
      .collection('products')
      .get()
      .then((snapShot) => {
        let tempArr = [];
        snapShot.docs.forEach((doc) => {
          tempArr.push({ id: doc.id, ...doc.data() });
        });
        this.getUserProducts(tempArr);
      });
  };

  getUserProducts = (allProducts) => {
    let _self = this;
    this.setState({ isLoading: true });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .firestore()
          .collection('user_subscriptions')
          .where('userId', '==', user.uid)
          .where('status.value', '==', 'active')
          .get()
          .then((snapshot) => {
            if (snapshot.docs.length > 0) {
              let selected = [];
              snapshot.forEach((doc) => {
                let index = allProducts.findIndex((item) => item.id == doc.data().product_id);
                if (index != -1) {
                  selected.push({ ...allProducts[index], purchase_date: doc.data().purchase_date });
                  allProducts.splice(index, 1);
                }
              });
              _self.setState({ isLoading: false, selected_products: selected, additional_products: allProducts });
            } else {
              _self.setState({ isLoading: false, additional_products: allProducts });
            }
          })
          .catch((err) => {
            //console.log(err);
            _self.setState({ isLoading: false });
          });
      } else {
        _self.setState({ isLoading: false });
      }
    });
  };

  goToReportList = (productId) => {
    let time = this.state.selected_products.find((item) => item.id == productId).purchase_date.seconds;

    localStorage.setItem('time', time);
    localStorage.setItem('product_id', productId);
    navigate(`/report-list/${productId}`);
  };

  goToDetails = (productId) => {
    switch (productId) {
      case 'EeP6fcSBKlft1TzCIuwK':
        navigate('/market-reports/etf/#plans');
        break;
      case 'MCFVXO6qx6IsH4ypqHqC':
        navigate('/market-reports/tech-stocks/#plans');
        break;
      case 'be0BRFRLo4zbeFKwHQxA':
        navigate('/market-reports/agricultural/#plans');
        break;
      case 'zyVDEytLZLzdG4HxSfeV':
        navigate('/market-reports/financial/#plans');
        break;
    }
  };

  render() {
    return (
      <>
        <meta name="description" content="user-products meta description" />
        <meta name="keywords" content="user-products, dashboard" />
        <section className="our-subscription-section footer-fix">
          <div className="container">
            {this.state.selected_products.length ? (
              <>
                <h4 className="text-center">Selected Plans</h4>
                <div className="plan-wrap mb-3">
                  {this.state.selected_products.map((product, i) => (
                    <div id="tech-stocks" className="plan-box" key={i}>
                      <img
                        src={
                          product.id == 'EeP6fcSBKlft1TzCIuwK'
                            ? ETFIcon
                            : product.id == 'MCFVXO6qx6IsH4ypqHqC'
                            ? TechIcon
                            : product.id == 'be0BRFRLo4zbeFKwHQxA'
                            ? AgsIcon
                            : FinIcon
                        }
                        width="100px"
                        alt="product icon"
                      />
                      <h4 className="plan-box-h4">{product.name}</h4>
                      <div className="plan-box-price-p" dangerouslySetInnerHTML={{ __html: product.description }} />
                      <ul className="plan-box-price-ul">
                        {product.selectedIns.map((ins, j) => (
                          <li className="plan-box-price-ul-li" key={j}>
                            <svg className="plan-box-price-ul-li-svg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-check"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </svg>
                            {ins.label}
                          </li>
                        ))}
                        <br />
                      </ul>
                      <a className="plan-box-price-select" href="javascript:void(0)">
                        <button className="plan-box-price-select-btn" onClick={() => this.goToReportList(product.id)}>
                          Open Report
                        </button>{' '}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            ) : null}

            {this.state.additional_products.length ? (
              <>
                <h4 className="text-center">Additional Plans</h4>
                <div className="plan-wrap">
                  {this.state.additional_products.map((product, i) => (
                    <div id="tech-stocks" className="plan-box" key={i}>
                      <img
                        src={
                          product.id == 'EeP6fcSBKlft1TzCIuwK'
                            ? ETFIcon
                            : product.id == 'MCFVXO6qx6IsH4ypqHqC'
                            ? TechIcon
                            : product.id == 'be0BRFRLo4zbeFKwHQxA'
                            ? AgsIcon
                            : FinIcon
                        }
                        width="100px"
                        alt="product icon"
                      />
                      <h4 className="plan-box-h4">{product.name}</h4>
                      <div className="plan-box-price-p" dangerouslySetInnerHTML={{ __html: product.description }} />
                      <ul className="plan-box-price-ul">
                        {product.selectedIns.map((ins, j) => (
                          <li className="plan-box-price-ul-li" key={j}>
                            <svg className="plan-box-price-ul-li-svg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-check"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </svg>
                            {ins.label}
                          </li>
                        ))}
                        <br />
                      </ul>
                      <a className="plan-box-price-select" href="javascript:void(0)">
                        <button className="plan-box-price-select-btn" onClick={() => this.goToDetails(product.id)}>
                          Select Product
                        </button>{' '}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
          <Loader isOpen={this.state.isLoading} />
        </section>
      </>
    );
  }
}

export default UserProducts;
